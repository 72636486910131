import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PageTitle from '../PageTitle';

function ListsMain() {
  const [lists, setLists] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/list_list.json");
        const jsonData = await response.json();
        console.log(jsonData);
        setLists(jsonData);
      } catch(error) {
        console.log(error, "error");
      }
    };
    fetchData();
  }, []);

  return (
    <div className="container-sm thin-container">
      <PageTitle title="Short Stories" />

      <Link to="/">
        <img src="tibbs-logo-border.svg" className="w-25 my-2" alt="Tibbs Logo" />
      </Link>

      <div className="filled-area p-4">
        <h1 className="mb-4">&gt;Lists</h1>

        <p>These are my current favorite things across various media. Yes, I am an obsessive list-maker.</p>
        
      </div>

      {lists.map(list_info => (
        <Link to={"/lists/"+list_info.slug}>
          <div className="filled-area-item p-4 m-3">
            <h4>{list_info.name}</h4>
          </div>
        </Link>
      ))}
    </div>
  );
}

export default ListsMain;